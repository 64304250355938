/*----------------------Fadeboxar-------------------------------*/

.fadeboxar  {padding-top:30px; }
.fadebox { height:190px; overflow:hidden; display:block; background-size:cover; background-position: center center; background-repeat:no-repeat; filter: grayscale(100%);
transition: filter 0.5s ease-in 0s; box-shadow:5px 0 0 0 #fff inset;  }
.fadebox_innerdiv {height:100%;  text-shadow: 1px 1px black; } 
/*.fadebox_innerdiv {height:100%;  opacity:0; transition: opacity 0.5s ease-in 0s; } */
.fadebox:hover .fadebox_innerdiv {opacity:1; transition: opacity 0.5s ease-in 0s; } 
.fadebox:hover {filter: grayscale( 0%); transition: filter 0.5s ease-in 0s; }

.fadebox_textdiv {padding: 0 2em; color:#fff; text-align: center; position: relative; }


.fadebox h2 {font-size: 1.4em;}

/*--------------------Mediaquerys fadeboxar--------------------*/
@media (max-width: 1300px) 
{
.fadebox, .zoombox {height:23vw}
}
@media (max-width: var(--breakpoint-m))
{
.fadebox_textdiv span, .zoombox_textdiv span {display:none;}
.fadeboxar.container, .zoomboxar.container, .fadeboxar.container.lrg, .zoomboxar.container.lrg {width: 100%;} 
}
@media (max-width: var(--breakpoint-s))
{
.fadebox, .zoombox {height:30vw;}
.fadebox_innerdiv, .zoombox_textdiv { opacity:1; color:#fff; }
.zoomboxar > a:hover .zoombox_innerdiv, .zoombox_innderdiv {transform:none;}
}
@media (max-width: var(--breakpoint-xs))
{
.fadebox, .zoombox {height:50vw;}
    .fadebox {margin-bottom:5px; }
}
