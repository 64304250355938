:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/theme/intendit/css/modules/fade_zoom.css";
/*
@import "/fontmodules/ABeeZee.css";
@import "/cssmodules/photoswipe.css";
@import "/cssmodules/animate.css";
*/
.medarbetare p {word-wrap: break-word;}



/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------GENERAL-----------------------------------------------------------------------*/
body {font-weight: 300; 
      color: #404040;
      line-height:1.4em;
      font-family: 'Hind', sans-serif;
     background: url('/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;  }
 
.startsida {background:#fff; }
.startsida .wrapper {max-width:5000px; padding:40px 0 0;   }
/*---------------------------------------------------------------DIVERSE "WRAPPERS"------------------------------------------------------------*/
.wrapper { background: #ffffff none repeat scroll 0 0;
    margin: 0 auto;
    max-width: 1360px;
    min-height: 100vh;
    padding: 0 30px;
    width: 100%;} 
 .container {width:100%; margin: 0 auto; }
.container.lrg {max-width: 1300px;}
.container.sml {max-width: 750px;}

.sida {padding: 40px 0 50px; } 

/*---------------------------------------------------------------BASIC ELEMENTS----------------------------------------------------------------*/
a, a:hover {color:#404040; font-weight:300; text-decoration: none;}
p {margin:3px 0 20px;}
strong {font-weight:500;}
iframe {max-width:100%;}

/*-------------------images--------------------*/
img {max-width:100%; height:auto;}
.bild {float:right; width:360px;  max-width:40%; height:auto;  margin: 0 0 30px 30px; overflow:hidden;}
.box_bild, .listing_bild {width:100%; flex:none; max-width:100%;} /*säkra vyn i IE*/
.bild img, .box_bild img, .listing_bild img, .galleri_bild img {width:100%; height:auto; } /*säkra vyn i IE*/
.medarbetare_bild img {width:100%; height:auto; max-width:100%; }


/*-------------------listor--------------------*/
.sida ul {list-style:disc; padding: 0 0 0 15px; }
.sida ul li {margin-bottom:3px;  }
 .referenser .sida ul {list-style:none; padding: 0; }
.referenser .sida ul li:before {content: "\f192"; margin:0 10px 0 0; font-family: FontAwesome;} 

/*------------readmore & buttons----------------*/
.button, .readmore, .boltforms-row button {background: #AE9B42; color:#fff; padding: 5px 0 5px; display:block; margin:0; text-align: center; border-radius:2px; line-height: 1.2em; border:none; transition:background 0.5s ease-in 0s;}
.readmore { width:100px;} .button, .boltforms-row button  { width:100%;}  
.button:hover, .readmore:hover, .boltforms-row button:hover {background:rgba(0, 0, 0, 0.3); transition:background 0.5s ease-in 0s;}
.boltforms-row button, .boltforms-row button:hover { color:#fff; }
button.slick-arrow {background:none;}


/*-----------------ikoner-----------------------*/
.fa.round {border-radius: 50%; padding: 11px 0 0; text-align: center; width: 45px; height: 45px; font-size:1.5em;background:rgba(0, 0, 0, 0.15);}
.fa.circle {border-radius: 50%; padding: 12px 0 0; text-align:center; width: 50px; height: 50px; font-size:1.5em; border: 1px solid #fff; color:#fff; display:block;}

.toptext .fa.round { padding: 13px 0 0; margin: 0 0 6px 6px; background: rgba(0, 0, 0, 0.5); color: #ffffff; height: 50px; width: 50px; /*clear:both; display:block;*/}
.sidfot .fa.round {background:rgba(255, 255, 255, 0.15); font-size:1.3em; width: 40px; height: 40px; padding: 11px 0 0;  }
.fadebox_textdiv .fa.circle { clear:both; margin: 10px auto 0; }
.box .fa {display:block; margin-bottom:15px; text-align:center; }

/*-------------egna klasser---------------------*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3) }
.shadowborder {box-shadow: 0px 0px 0px 1px #e4e4e4;}
.pubdatum {color:#909090; font-size:95%; display:block; clear:both;  }
.record {border-bottom:1px solid #e4e4e4; }

/*---------------------------------------------------------------FONTS------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {margin: 0; letter-spacing:0pt; line-height:1.2em; padding: 0px; font-weight:400;  font-family: 'Lustria', serif;}
h1{font-size: 2.2em;padding: 0px 0 20px;  text-transform:uppercase; color:#202020;  font-weight:700;  margin: 0 0 20px; border-bottom: 2px solid #e4e4e4; text-align:center;letter-spacing:3pt;  }
h2{font-size: 1.7em;}
h3{font-size: 1.15em;  }
h4{font-size: 1em;}
h5{font-size: 1em;}

.highlight {font-size:4em; line-height:1.2em; padding: 20px 0 ; color:#e4e4e4; font-weight:300; }
.sida.listing h1 {padding-bottom:40px; margin-bottom:40px; border-bottom:1px solid #e4e4e4;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------STARTSIDAN various------------------------------------------------------------------------*/ 
.toptext { position:absolute; right:10px; top:90px; z-index:1;  display:none;  } /*display none by default*/
.topbild {height:45vh; max-height:45vw; background-position: center center; background-size:cover; position: relative;}


/*topgallery*/
.topgallery  {  height:45vh; max-height:45vw; position: relative;}
.topgallery > div{ background-size: cover; background-position:center center; height:50%; margin-left:0; margin-right:0; margin-bottom:0;}
.topgallery > div:nth-child(-n + 3) {margin-bottom:5px;}

.topgallery > div:nth-child(-n + 2) {margin-right:5px;}
.topgallery > div:nth-child(4) {margin-right:5px;}
.topgallery > div:nth-child(5) {margin-right:5px;}


/*----------------------Bildspel-----------------------*/
.slick-slider {margin:0 auto; position: relative;}
.slick-slide {background-size:cover; background-position: center center; min-height:55vh; display:flex !important;  }   
.bildspel_titel {width:100%; text-align:center; font-size: 3.5em; line-height:1.1em; padding: 0px; text-align:center;}
 
.tjanst {border-bottom: 2px solid #ae9b42;margin-bottom: 10px; padding-bottom: 10px;}
.tjanst h3:before {content:"\f101"; margin: 0 10px 0 0;   font-family: FontAwesome;}

.projekt .listing_bild {max-height:230px; overflow:hidden; margin-bottom:10px;  }

.title h1 {
    margin-top: 25px;
    font-size: 18px;   
}

.maincontent {
 text-align: center;   
}









/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*---------------------------------------------------------------GALLERI ------------------------------------------------------------------*/
.magnificgalleri, .wrapper .loggagalleri {padding: 30px 0 0; margin-top:10px; border-top: 1px solid #d0d0d0; }
.galleri_bild a {display:block; }
.galleri_bild {box-shadow: 1px 1px 0 0 #e4e4e4;} .galleri_bild:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.galleri_bild img {padding:20px;}
.thumbtitel {font-size:90%; }  

/*logo gallery*/
.loggagalleri .logga {min-height: 70px; }
.loggagalleri .logga img {height:auto; flex:0 0 auto;}/*fix for IE*/
.standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}   
.mfp-arrow, .mfp-arrow:before,.mfp-arrow:after {border:none;  height:100px; color:#fff; font-size:60px; font-family: FontAwesome; opacity:0.9; }
.mfp-arrow-left:before {content:"\f104"; } .mfp-arrow-right:before {content:"\f105"; }
button.mfp-close { font-size: 3.4em; margin:0 -2px 0 0; opacity: 0.9;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------------------------------------------------KONTAKT SAKER------------------------------------------------------------------*/
/*Kontaktbanner & Formulär*/
.boltforms-row label, .boltforms-row button {font-weight: 400; margin-bottom: 0;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060;  }

.kontaktbanner {background: #D1C3B8; padding:50px 0; color:#fff; box-sizing: border-box;}
.kontaktbanner h2 { padding:0px 0 10px; }
.kontaktbanner .boltforms-row {float:left; width:33.3%; padding-right:10px;}
.kontaktformular .boltforms-row, .kontaktbanner .boltforms-row:nth-child(n+4) {width:100%;}

/*Googlekarta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}

/*Sifoten*/
.startsida .sidfot {max-width:5000px; }
.sidfot {padding: 50px 0 200px; width:100%; max-width:1360px; margin:0 auto; background:#e4e4e4;  }
.sidfot, .sidfot a {color:#939393; font-weight:300; }
.sidfot h3 {padding: 0 0 5px;} 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
header {text-transform:uppercase; width:100%; background:#fff; z-index:10; position:relative; top:0; }
body.scrolled header {}

/*Logotype*/
.logo {padding:30px 15px 15px; box-sizing:border-box; } 
.logo img {max-height: 40px; width:auto; max-width:calc(100vw - 100px)}
.logo a {padding:0; display:block;}  
.startsida .logo img  { max-height:32px; width:auto;  }

/*Standard meny + hovers*/
nav {background:inherit;   background: inherit;  border-bottom: 1px solid #e4e4e4;  border-top: 1px solid #e4e4e4; margin-bottom:25px !important;  padding: 7px 0 5px;} 
header nav ul.menu > li {padding: 0 5px; margin:0;  }
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px; margin:0; letter-spacing:0; /*transition:color 0.5s ease-in 0s;*/ color:#808080;}
nav ul.menu li a:hover, nav ul.menu li.active a {}
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family:FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }

/*Mobile menu under 800px - fixed header*/
.mobile-menu header {position:fixed; top:0; min-height:80px; box-shadow:none; flex-wrap:nowrap; border-bottom: 1px solid #e4e4e4;} /*background på header bar*/
.mobile-menu .wrapper {margin-top:100px; padding:0; }  /*knuffar ner innehåll bakom menyn - justera höjd*/
.mobile-menu .logo {padding:10px 0px 0 20px; } 

.mobile-menu nav {border:none; }
.mobile-menu nav ul.menu {margin-top:1px; background:#fff; }
.mobile-menu nav ul.menu.show { max-height:calc(100vh - 80px); }  /*räknar höjd - fixed header*/
.mobile-menu nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
.mobile-menu nav .menu .dropdown ul {padding:0; left:0px;} 
.mobile-menu nav ul.menu li.dropdown > a:after {display:none; }
.mobile-menu  nav ul.menu li a {color:#404040; }
nav #menu-icon {padding: 20px 20px 0 0;  }
nav #menu-icon span {border-radius:3px;}

body.mobile-menu {background-image:none; }


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}    
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
/*Containers*/
.container, .container.lrg {width: 98%;}  
header.container, header.container.lrg {width: 100%;}  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
 h1 {font-size: 1.8em;  }
    
/*Fontsize*/ 
body { line-height:1.25em; font-size: 0.97em; }
    
/*Slider height*/  
.slick-slide {min-height:55vw;}
.topgallery  {height:45vw; }
.topgallery > div{ height:22vw; }
    
    
/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.galleri_bild:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
.bild { margin: 0 0 20px 20px;  }      
   
/*Sidfot*/   
.sidfot .s-twelve {border-bottom: 1px solid #939393;margin: 0 auto;max-width: 400px; padding: 20px 0;text-align: center; }
.sidfot .s-twelve:last-child {border-bottom: none; }   
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
.mobile-menu .logo img { max-width:calc(100vw - 60px)}    
nav #menu-icon {padding: 0; position:fixed; top:43px; right:10px; }
    
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.kontaktbanner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.galleri_bild:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.galleri_bild img {padding: 13px;}    
    
/*map*/
.map-canvas {width:100%; max-height:200px; }
    
/*table varje cell under varann med border efter varje rad 
table tr td {display: list-item; list-style: none; padding:0; }
table tr td:first-child {padding: 10px 0 0;}
table tr td:last-child {padding: 0 0 10px;}*/     
}

@media (max-width: 350px)
{
/*bild*/
.bild {width:100%; max-width:100%; margin: 0 0 10px; float:none; }     
.bild img {width:100%; height:auto;}    
}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/




 

/* --------------------------------------------------------Sliding border under text--------------------------------------------------------- 
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition: width 0.5s ease-in 0s; transition: margin 0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; }*/

/* ------------------------------------------------------------Tables--------------------------------------------------------------------------
table { margin:30px 0; font-size:95%; }
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:5px 0px 5px 0;}

/* ------------------------------------------------------------Sökning--------------------------------------------------------------------------
.searchbox {}
#searchform {margin:0; }
#searchform input {margin:0; height: 23px; padding: 3px 10px; color:#959595; display: inline-block; }
.search {float: none;padding: 0;}*/

/* ------------------------------------------------------------Egen menu -icon-----------------------------------------------------------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/icons/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/


/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
